import React, { FC, ReactNode, useMemo } from 'react';
import { clusterApiUrl } from '@solana/web3.js';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { SettingsFormState } from '../../../models';
import { useSettingsStorage } from '../../../hooks';
import { ProgramProvider } from './ProgramContext';

export interface WalletContextProps {
  children: ReactNode;
}

const clusterNetworkApi = ({ network, solanaRpcApi }: SettingsFormState): string => {
  return network === 'custom' ? solanaRpcApi : clusterApiUrl(network);
};

export const WalletContext: FC<WalletContextProps> = ({ children }) => {
  const [settings] = useSettingsStorage();

  const endpoint = useMemo(() => clusterNetworkApi(settings), [settings]);
  const wallets = useMemo(() => [new PhantomWalletAdapter()], [settings]);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={true}>
        <WalletModalProvider>
          <ProgramProvider>
            {children}
          </ProgramProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
