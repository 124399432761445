import { PublicKey } from '@solana/web3.js';
import { GroupData, ProposalData } from '../programs/multisig/models/schema';
import { GroupsState } from './groups';

export interface GroupProposal {
  proposal: ProposalData;
  group?: GroupData;
  publicKey: PublicKey;
}

export interface GroupProposalList {
  proposal: ProposalData;
  proposalKey: string;
  type: string;
}

export interface ProposalsCombine {
  groups: GroupsState[];
  proposals: GroupProposal[];
}

export const enum ProgramBufferType {
  start = 'start',
  stop = 'stop'
}

export interface ProgramBuffer {
  id: number;
  title: string;
  name: string;
  type: ProgramBufferType;
  publicKey: string;
}
