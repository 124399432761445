import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { SuccessIcon } from '../../common/icons';
import { signatureLink } from '../../common/components/common/notifications';
import { useProgram } from '../../common/components/wallet/ProgramContext';
import { environment } from '../../environments/config/environment';
import { useSettingsStorage } from '../../hooks';
import { QueryParams, RoutePath } from '../../models';

const ProposalCreatedPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [settings] = useSettingsStorage();
  const { publicKey: walletKey } = useWallet();
  const { bufferStorage } = useProgram();

  const signature = useMemo(() => {
    const signature = searchParams.get(QueryParams.signature);
    if (signature) {
      return signatureLink(environment.url.explorer, signature, {
        cluster: 'custom',
        customUrl: settings.solanaRpcApi,
      });
    }
    return '';
  }, [searchParams]);

  useEffect(() => {
    if (!walletKey) {
      navigate(`/${RoutePath.proposals}`);
    }
  }, [walletKey]);

  useEffect(() => {
    return () => {
      bufferStorage?.deleteBuffer();
    };
  }, [bufferStorage?.data]);

  const title = useMemo(() => {
    return <>Proposal to {bufferStorage?.data.type?.toUpperCase()} EVM<br /> successfully created</>;
  }, [bufferStorage?.data]);

  return (<>
    <div className={'flex flex-grow w-full'}>
      <div className={'flex flex-col justify-center w-full items-center text-center'}>
        <SuccessIcon className={'mb-4'} />
        <h1 className={'text-xl font-bold mb-4'}>{title}</h1>
        <div className={'text-base px-4 py-3 w-full border border-[#E9E9E9] rounded-[12px] mb-10 max-w-md'}>
          Notifications has been sent to all Engineers
        </div>
      </div>
    </div>
    {signature &&
      <a target={'_blank'} href={signature} rel='noreferrer'
         className={'text-[#194CFF] text-base mb-6'}>
      See transaction on Solana Explorer
    </a>}
    <Link to={`/${RoutePath.proposals}`} className={'button black w-full'}>Close</Link>
  </>);
};

export default ProposalCreatedPage;
