import React, { lazy } from 'react';
import { RoutePath } from '../../models';
import LayoutContent from './Layout';

const GroupPage = lazy(() => import('../../multisig/pages/GroupPage'));
const GroupsPage = lazy(() => import('../../multisig/pages/GroupsPage'));
const GroupCreatePage = lazy(() => import('../../multisig/pages/GroupCreatePage'));
const ProposalCreatePage = lazy(() => import('../../multisig/pages/ProposalCreatePage'));
const ProposalPage = lazy(() => import('../../multisig/pages/ProposalPage'));

export const multiSigRouterPath = {
  path: RoutePath.home, element: <LayoutContent />, children: [
    { path: RoutePath.groups, element: <GroupsPage /> },
    { path: RoutePath.group, element: <GroupPage /> },
    { path: RoutePath.groupCreate, element: <GroupCreatePage /> },
    { path: RoutePath.proposalCreate, element: <ProposalCreatePage /> },
    { path: RoutePath.groupProposal, element: <ProposalPage /> },
  ],
};
