import { Dispatch, SetStateAction } from 'react';
import { CommonApi } from '../../api/CommonApi';
import { MaintenanceProgram } from '../../programs/maintenance/program';
import {
  NeonProgramStatus,
  ProgramBufferStorage,
  ProposeFormStorage,
  RequestBuffers
} from '../../models';

export interface ProgramContextState {
  api: CommonApi;
  program: MaintenanceProgram;
  accountStorage: AccountStorage;
  buffers: RequestBuffers;
  proxyInfo?: NeonProgramStatus;
  accounts?: AccountData[];
  bufferStorage: ProgramBufferStorage;
  formStorage: ProposeFormStorage;
}

export interface AccountData {
  id: number;
  name: string;
  account: string;
  access?: boolean;
}

export type LoginState = [boolean, Dispatch<SetStateAction<boolean>>];

export const enum AccountStorageKey {
  account = 'account',
  buffer = 'buffer',
  comment = 'comment'
}

export interface AccountDataStorage {
  account: AccountData;
  publicKey: string;
  advanced: boolean;
  tooltip: boolean;
}

export interface NeonProgram {
  programKey: string;
  authorityKey: string;
}

export interface AccountStorage {
  account: AccountDataStorage,
  setAccount: (value: AccountDataStorage) => void;
  deleteAccount: () => void;
}
