import {
  AccountInfo,
  AccountMeta,
  Commitment,
  ComputeBudgetProgram,
  Connection,
  ParsedAccountData,
  PublicKey,
  RpcResponseAndContext,
  SignatureResult,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
  TransactionSignature
} from '@solana/web3.js';
import { Buffer } from 'buffer';
import { serialize } from 'borsh';
import { encode } from 'bs58';
import { ACCOUNT_TYPE_TAG, MultiSigProgram } from '../multisig/program';
import { UPGRADEABLE_BPF_LOADER_PROGRAM_ID } from '../multisig/loader';
import {
  CloseProposalInstruction,
  InstructionData,
  multiSigSchema,
  ProposalConfig,
  ProposedAccountMeta,
  ProposedInstruction,
  ProposeInstruction
} from '../multisig/models/schema';
import { deserializeGroupData, groupsList } from '../multisig/groups';
import { groupProposals } from '../multisig/proposals';
import { GroupProposal, GroupsState, ProgramBufferType, ProposalsCombine } from '../../models';

export class MaintenanceProgram {
  maintenanceKey: PublicKey;
  neonEmvKey: PublicKey;
  multiSigKey: PublicKey;
  memoKey: PublicKey;
  payerKey: PublicKey;
  multiSigProgram: MultiSigProgram;
  connection: Connection;

  constructor(config: { maintenance: string, neonEvm: string, multisig: string, memo: string, payer: string, connection: Connection }) {
    const { maintenance, neonEvm, multisig, connection, memo, payer } = config;
    this.maintenanceKey = new PublicKey(maintenance);
    this.neonEmvKey = new PublicKey(neonEvm);
    this.multiSigKey = new PublicKey(multisig);
    this.memoKey = new PublicKey(memo);
    this.payerKey = new PublicKey(payer);
    this.multiSigProgram = new MultiSigProgram(this.multiSigKey);
    this.connection = connection;
  }

  getMaintenanceRecordAddress(neonEvmKey: PublicKey, maintenanceKey: PublicKey): Promise<[PublicKey, number]> {
    return PublicKey.findProgramAddress([new Buffer('maintenance', 'utf8'), neonEvmKey.toBuffer()], maintenanceKey);
  }

  createUpgradeInstruction(
    maintenanceProgram: PublicKey,
    maintenanceRecord: PublicKey,
    programBuffer: PublicKey, // new buffer for program
    authority: PublicKey, // Protected group key
    spillAddress: PublicKey // Wallet key
  ): TransactionInstruction {
    const keys: AccountMeta[] = [
      { pubkey: UPGRADEABLE_BPF_LOADER_PROGRAM_ID, isSigner: false, isWritable: false }, /// 0. `[]` Bpf Loader Upgradeable Program Id
      { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false }, /// 1. `[]` Sysvar Rent Program Id
      { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false }, /// 2. `[]` Sysvar Clock Program Id
      { pubkey: this.neonEmvKey, isSigner: false, isWritable: true }, /// 3. `[writable]` Maintained program account
      { pubkey: maintenanceProgram, isSigner: false, isWritable: true }, /// 4. `[writable]` Maintained program data account
      { pubkey: programBuffer, isSigner: false, isWritable: true }, /// 5. `[writable]` Upgrade buffer account
      { pubkey: maintenanceRecord, isSigner: false, isWritable: false }, /// 6. `[]` MaintenanceRecord
      { pubkey: authority, isSigner: true, isWritable: false }, /// 7. `[signer]` Authority
      { pubkey: spillAddress, isSigner: false, isWritable: true } /// 8. `[writable]` Spill account
    ];

    console.table(keys.map(({ pubkey, ...data }) => ({ pubkey: pubkey.toBase58(), ...data })));

    return new TransactionInstruction({
      programId: this.maintenanceKey,
      keys,
      data: Buffer.from([0x03])
    });
  }

  createBufferAuthorityInstruction(programBuffer: PublicKey, currentAuthority: PublicKey, newAuthority: PublicKey): TransactionInstruction {
    const keys: AccountMeta[] = [
      { pubkey: programBuffer, isWritable: true, isSigner: false }, // buffer
      { pubkey: currentAuthority, isWritable: false, isSigner: true }, // protected group
      { pubkey: newAuthority, isWritable: false, isSigner: false } // maintenance record
    ];
    return new TransactionInstruction({
      programId: UPGRADEABLE_BPF_LOADER_PROGRAM_ID,
      keys,
      data: Buffer.from([4, 0, 0, 0])
    });
  }

  async getBufferAuthority(programBuffer: PublicKey): Promise<{ authority?: PublicKey, owner?: PublicKey }> {
    const account = await this.connection.getParsedAccountInfo(programBuffer);
    const result: { authority?: PublicKey, owner?: PublicKey } = {};
    if (account) {
      try {
        const { owner, data } = account.value ?? {};
        result['owner'] = owner;
        const parsedAccountData = data as ParsedAccountData;
        if (parsedAccountData?.parsed?.info?.authority) {
          result['authority'] = new PublicKey(parsedAccountData.parsed.info.authority);
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          console.log(e?.message);
        }
      }
    }
    return result;
  }

  async propose(context: {
    programBuffer: PublicKey,
    groupAccount: PublicKey,
    walletKey: PublicKey,
    spillAddress: PublicKey,
    comment: string,
    type: ProgramBufferType
  }): Promise<{ proposalKey: PublicKey, transaction: Transaction, protectedGroup: PublicKey }> {
    const { programBuffer, groupAccount, walletKey, spillAddress, comment, type } = context;
    const transaction = new Transaction({ feePayer: walletKey });
    const protectedGroup = await this.multiSigProgram.protectedAccountKey(groupAccount);
    // const { authority, owner } = await this.getBufferAuthority(programBuffer);
    const [maintenanceProgram] = await PublicKey.findProgramAddress([this.neonEmvKey.toBuffer()], UPGRADEABLE_BPF_LOADER_PROGRAM_ID);
    const [maintenanceRecord] = await this.getMaintenanceRecordAddress(this.neonEmvKey, this.maintenanceKey);

    // console.log(authority?.toBase58(), protectedGroup?.equals(authority!), owner?.equals(UPGRADEABLE_BPF_LOADER_PROGRAM_ID));
    // console.log(protectedGroup.toBase58());

    // if (!(owner?.equals(UPGRADEABLE_BPF_LOADER_PROGRAM_ID)) || !(authority?.equals(protectedGroup))) {
    //   throw new Error(`Error: Buffer isn't allowed, prepare buffer before create proposal`);
    // }
    //
    // if (!maintenanceRecord.equals(authority)) {
    //   transaction.add(this.createBufferAuthorityInstruction(programBuffer, protectedGroup, maintenanceRecord));
    // }

    const {
      programId,
      keys,
      data
    } = await this.createUpgradeInstruction(maintenanceProgram, maintenanceRecord, programBuffer, protectedGroup, spillAddress);
    const meta = keys.map(k => new ProposedAccountMeta(k.pubkey, k.isSigner, k.isWritable));
    const proposedInstruction = new ProposedInstruction(programId, meta, data);
    const memoInstruction = new ProposedInstruction(this.memoKey, [], Buffer.from(comment));
    const salt = `${Date.now().toString()}${type === ProgramBufferType.stop ? '1' : ''}`;
    const proposalConfig = new ProposalConfig({
      group: Uint8Array.from(protectedGroup.toBuffer()),
      instructions: [proposedInstruction, memoInstruction],
      author: Uint8Array.from(walletKey.toBuffer()),
      salt
    });
    const proposalKey = await this.multiSigProgram.proposalAccountKey(proposalConfig);
    const proposalSpace = this.multiSigProgram.proposalAccountSpace(proposalConfig);
    const rent = await this.connection.getMinimumBalanceForRentExemption(proposalSpace);
    const proposeInstruction = new ProposeInstruction([proposedInstruction, memoInstruction], rent, parseInt(salt));

    transaction.add(await this.multiSigProgram.propose(proposeInstruction, groupAccount, walletKey));
    return { proposalKey, transaction, protectedGroup };
  }

  async approve(context: { proposalKey: PublicKey, walletKey: PublicKey, budget?: boolean }): Promise<Transaction> {
    const { proposalKey, walletKey, budget } = context;
    const proposalAccount = await this.findAccount(proposalKey);
    const proposalData = this.multiSigProgram.readProposalAccountData(proposalAccount);
    const approve = await this.multiSigProgram.approve(proposalKey, proposalData.config, walletKey);
    const transaction = new Transaction();
    if (budget) {
      transaction.add(ComputeBudgetProgram.requestUnits({ units: 1200000, additionalFee: 0 }));
    }
    return transaction.add(approve);
  }

  async closeTransaction(context: { proposalKey: PublicKey, groupKey: PublicKey, walletKey: PublicKey }): Promise<Transaction> {
    const { proposalKey, walletKey, groupKey } = context;
    await this.findAccount(proposalKey);
    const instructionData = new InstructionData(new CloseProposalInstruction());
    const buffer = serialize(multiSigSchema, instructionData);
    const keys = [
      { pubkey: walletKey, isSigner: true, isWritable: true },
      { pubkey: proposalKey, isSigner: false, isWritable: true },
      { pubkey: groupKey, isSigner: false, isWritable: true }
    ];
    const data = Buffer.from(buffer);
    const instruction = new TransactionInstruction({ programId: this.multiSigKey, keys, data });
    return new Transaction().add(instruction);
  }

  async findAccount(proposalKey: PublicKey): Promise<AccountInfo<Buffer>> {
    const account = await this.connection.getAccountInfo(proposalKey);
    if (account === null) {
      throw `Error: Can't find the Proposal Account`;
    }
    return account;
  }

  async groups(walletKey: PublicKey): Promise<GroupsState[]> {
    const multiSig = this.multiSigProgram;
    // await maintenanceGroupsList(this.connection, this, walletKey);
    return await groupsList(this.connection, multiSig, walletKey);
  }

  async proposals(walletKey: PublicKey): Promise<ProposalsCombine> {
    const multiSig = this.multiSigProgram;
    const groups = await groupsList(this.connection, multiSig, walletKey);
    const data = groups.map(group => {
      return groupProposals(this.connection, multiSig, group);
    });
    const items = await Promise.all(data);
    return { groups, proposals: items.flat() };
  }

  async proposalsAll(walletKey?: PublicKey): Promise<ProposalsCombine> {
    const result: ProposalsCombine = { groups: [], proposals: [] };
    const walletB58 = walletKey?.toBase58();
    const bytes = encode([ACCOUNT_TYPE_TAG['group']]);
    const memFilter = { memcmp: { bytes, offset: 0 } };

    const accounts = await this.connection.getProgramAccounts(this.multiSigKey, {
      commitment: 'confirmed',
      filters: [memFilter]
    });

    const data = accounts.map((data) => {
      const group = { group: deserializeGroupData(data.account), publicKey: data.pubkey };
      if (walletB58 && group.group.members.some(member => encode(member.publicKey) === walletB58)) {
        result['groups'].push(group);
      }
      return groupProposals(this.connection, this.multiSigProgram, group);
    });
    const proposals = await Promise.all(data);
    result['proposals'] = proposals.flat();
    return result;
  }

  async proposal(proposalKey: PublicKey): Promise<GroupProposal | null> {
    const result: Partial<GroupProposal> = {};
    const multiSig = this.multiSigProgram;
    const account = await this.connection.getAccountInfo(proposalKey);
    if (account) {
      const proposal = await multiSig.readProposalAccountData(account);
      if (proposal) {
        result['proposal'] = proposal;
        result['publicKey'] = proposalKey;
        const groupKey = new PublicKey(proposal.config.group);
        const groupAccount = await this.connection.getAccountInfo(groupKey);
        if (groupAccount) {
          result['group'] = multiSig.readGroupAccountData(groupAccount);
        }
      }
    } else {
      throw 'Proposal not found';
    }
    return result as GroupProposal;
  }

  async confirmTransaction(signature: TransactionSignature, commitment: Commitment): Promise<RpcResponseAndContext<SignatureResult> | null> {
    try {
      const { blockhash, lastValidBlockHeight } = await this.connection.getLatestBlockhash();
      return this.connection.confirmTransaction({
        blockhash,
        lastValidBlockHeight,
        signature
      }, commitment);
    } catch (e: unknown) {
      console.log(e instanceof Error ? e?.message : '');
      return Promise.resolve(null);
    }
  }
}
