import { CommonApi } from '../api/CommonApi';
import { useEffect, useState } from 'react';
import { ProgramBuffer, ProgramBufferStorage, ProposeFormStorage, RequestBuffers } from '../models';
import { useSettingsStorage } from './settings';
import { useLocalStorage } from './storage';
import { AccountStorageKey, ProposeFormData } from '../maintenance/models';

export const useBufferStorage = (): ProgramBufferStorage => {
  const [data, setBuffer, deleteBuffer] = useLocalStorage<ProgramBuffer>(AccountStorageKey.buffer, {} as ProgramBuffer);
  return { data, setBuffer, deleteBuffer };
};

export const useFormStorage = (): ProposeFormStorage => {
  const [data, setFormData, deleteFormData] = useLocalStorage<ProposeFormData>(AccountStorageKey.comment, {} as ProposeFormData);
  return { data, setFormData, deleteFormData };
};

export function useRequestBuffers(api: CommonApi): RequestBuffers {
  const [data, setBuffers] = useState<ProgramBuffer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [settings] = useSettingsStorage();

  const buffersList = async (): Promise<void> => {
    setLoading(true);
    const data = await api.buffer();
    setBuffers(data);
    setLoading(false);
  };

  const addBuffer = async (item: ProgramBuffer): Promise<void> => {
    const newData = [...data, item];
    await setBuffers(newData);
  };

  useEffect(() => {
    buffersList();
  }, [settings]);

  return { data, addBuffer, loading };
}
