export const environment = {
  governanceProgram: '82pQHEmBbW6CQS8GzLP3WE2pCgMUPSW2XzpuSih3aFDk', // GOVERNANCE_PROGRAM
  neonEvmProgram: 'DCPSnJHB38e7vNK6o3AVLswJGRaP87iiNx2zvvapiKBz', // NEON_EVM_PROGRAM
  maintenanceProgram: '7aPH9mBAvUtJDGV2L1KyvpR5nKF7man5DZzBPaxmisg5', // MAINTENANCE_PROGRAM
  multisigProgram: 'C7VZKm4FxXEwrtNGtKzH7K3x1CJfoxSV3vKFqPnEpTqb', // MULTISIG_PROGRAM
  memo: 'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr', // MEMO_PROGRAM
  payer: '9G9A27t7FEEP5L53svAPweJyZCDFESsGd5tvnQEZFJUY', // FEE_PAYER
  url: {
    explorer: 'https://explorer.solana.com',
    solanaRpcApi: 'https://api.dao.neontest.xyz/node-solana', // 'https://proxy.release.stand.neontest.xyz/node-solana',
    neonProxyRpcApi: 'https://api.dao.neontest.xyz' // 'https://proxy.devnet.neonlabs.org/solana',
  },
};
// https://api.dao.neontest.xyz
// https://api.dao.neontest.xyz/node-solana
