import {
  AccountInfo,
  ComputeBudgetProgram,
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction
} from '@solana/web3.js';
import * as bs58 from 'bs58';
import { Buffer } from 'buffer';
import { deserialize } from 'borsh';
import {
  multiSigSchema,
  ProposalConfig,
  ProposalData,
  ProposedAccountMeta,
  ProposedInstruction,
  ProposeInstruction
} from './models/schema';
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Proposition, PropositionKind } from './models/proposal';
import { ACCOUNT_TYPE_TAG, MultiSigProgram } from './program';
import { createTokenAccount } from './tokens';
import { Loader, UPGRADEABLE_BPF_LOADER_PROGRAM_ID } from './loader';
import { MaintenanceProgram } from '../maintenance/program';
import { GroupProposal, GroupsState } from '../../models';

export function deserializeGroupProposalData(info: AccountInfo<Buffer>): ProposalData {
  return deserialize<ProposalData>(multiSigSchema, ProposalData, info.data.slice(1));
}

export async function groupProposals(connection: Connection, multisig: MultiSigProgram, group: Partial<GroupsState>): Promise<GroupProposal[]> {
  try {
    const arrayOne = new Uint8Array([ACCOUNT_TYPE_TAG['proposal']]);
    const arrayTwo = group.publicKey!.toBytes();
    const mergedArray = new Uint8Array(arrayOne.length + arrayTwo.length);
    mergedArray.set(arrayOne);
    mergedArray.set(arrayTwo, arrayOne.length);
    const bytes = bs58.encode(mergedArray);
    const memFilter = { memcmp: { bytes, offset: 0 } };

    const accounts = await connection.getProgramAccounts(multisig.programId, {
      commitment: 'confirmed',
      filters: [memFilter]
    });
    return accounts.map(({ account, pubkey }) => ({
      group: group.group,
      proposal: deserializeGroupProposalData(account),
      publicKey: pubkey
    }));
  } catch (e) {
    console.log(e);
  }
  return [];
}

export async function proposeInstructions(
  context: { connection: Connection, program: MultiSigProgram, emergency: MaintenanceProgram, protectedGroupAccount: PublicKey, proposition: Proposition, walletKey: PublicKey }
): Promise<TransactionInstruction[]> {
  const { connection, program, protectedGroupAccount, proposition, emergency, walletKey } = context;
  const [maintenanceProgram] = await PublicKey.findProgramAddress([emergency.neonEmvKey.toBuffer()], UPGRADEABLE_BPF_LOADER_PROGRAM_ID);
  const [maintenanceRecord] = await emergency.getMaintenanceRecordAddress(emergency.neonEmvKey, emergency.maintenanceKey);
  let proposedInstructions: TransactionInstruction[] = [];
  switch (proposition.kind) {
    case PropositionKind.Create:
      proposedInstructions = [
        SystemProgram.createAccount({
          fromPubkey: proposition.finalApproverKey, // должны иметь подпись, должен подписывать транзакцию
          newAccountPubkey: protectedGroupAccount,// должны иметь подпись
          lamports: proposition.lamports,
          space: 0,
          programId: SystemProgram.programId
        })
      ];
      break;
    case PropositionKind.Transfer:
      proposedInstructions = [
        SystemProgram.transfer({
          fromPubkey: protectedGroupAccount,
          toPubkey: proposition.destination,
          lamports: proposition.amount
        })
      ];
      break;
    case PropositionKind.Upgrade:
      proposedInstructions = [
        await Loader.upgradeInstruction(
          proposition.program,
          proposition.buffer,
          protectedGroupAccount,
          protectedGroupAccount
        )
      ];
      break;
    case PropositionKind.UpgradeMultisig:
      proposedInstructions = [
        await Loader.upgradeInstruction(
          program.programId,
          proposition.buffer,
          protectedGroupAccount,
          protectedGroupAccount
        )
      ];
      break;
    case PropositionKind.UpgradeEVM:
      proposedInstructions = [
        emergency.createUpgradeInstruction(maintenanceProgram, maintenanceRecord, proposition.buffer, protectedGroupAccount, walletKey)
      ];
      break;
    case PropositionKind.DelegateUpgradeAuthority:
      proposedInstructions = [
        await Loader.setUpgradeAuthorityInstruction(
          proposition.target,
          protectedGroupAccount,
          proposition.newAuthority
        )
      ];
      break;
    case PropositionKind.DelegateTokenAuthority:
      proposedInstructions = [
        Token.createSetAuthorityInstruction(
          TOKEN_PROGRAM_ID,
          proposition.target,
          proposition.newAuthority,
          'AccountOwner',
          protectedGroupAccount,
          []
        )
      ];
      break;
    case PropositionKind.DelegateMintAuthority:
      proposedInstructions = [
        Token.createSetAuthorityInstruction(
          TOKEN_PROGRAM_ID,
          proposition.target,
          proposition.newAuthority,
          'MintTokens',
          protectedGroupAccount,
          []
        )
      ];
      break;
    case PropositionKind.MintTo:
      proposedInstructions = [
        Token.createMintToInstruction(
          TOKEN_PROGRAM_ID,
          proposition.mint,
          proposition.destination,
          protectedGroupAccount,
          [],
          proposition.amount
        )
      ];
      break;
    case PropositionKind.CreateTokenAccount:
      proposedInstructions = await createTokenAccount(
        connection,
        protectedGroupAccount,
        proposition.mint,
        proposition.seed
      );
      break;
    case PropositionKind.TransferToken:
      proposedInstructions = [
        Token.createTransferInstruction(
          TOKEN_PROGRAM_ID,
          proposition.source,
          proposition.destination,
          protectedGroupAccount,
          [],
          proposition.amount
        )
      ];
      break;
    default:
      throw 'unsupported proposition';
  }

  console.log(proposedInstructions);
  return proposedInstructions;
}

export async function proposeCreate(context: {
  connection: Connection,
  program: MultiSigProgram,
  walletKey: PublicKey,
  groupAccount: PublicKey,
  instructions: TransactionInstruction[],
}): Promise<{ proposalKey: PublicKey, transaction: Transaction }> {
  try {
    const { connection, program, walletKey, groupAccount, instructions } = context;
    const transaction = new Transaction();
    const proposedInstructions = instructions.map(instruction => {
      return new ProposedInstruction(
        instruction.programId,
        instruction.keys.map(key => new ProposedAccountMeta(key.pubkey, key.isSigner, key.isWritable)),
        instruction.data
      );
    });

    const salt = Date.now();
    const proposalConfig = new ProposalConfig({
      group: Uint8Array.from(groupAccount.toBuffer()),
      instructions: proposedInstructions,
      author: Uint8Array.from(walletKey.toBuffer()),
      salt
    });
    const proposalKey = await program.proposalAccountKey(proposalConfig);
    const rent = await connection.getMinimumBalanceForRentExemption(program.proposalAccountSpace(proposalConfig));

    const proposeInstruction = new ProposeInstruction(proposedInstructions, rent, salt);

    transaction.add(await program.propose(proposeInstruction, groupAccount, walletKey));
    return { proposalKey, transaction };
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
}

export async function proposeApprove(context: { connection: Connection, program: MultiSigProgram, proposalKey: PublicKey, walletKey: PublicKey }): Promise<Transaction> {
  const { connection, program, proposalKey, walletKey } = context;
  console.log(`Signing with account: ${walletKey.toBase58()}`);

  const proposalAccountInfo = await connection.getAccountInfo(proposalKey);
  if (proposalAccountInfo === null) {
    throw 'Error: cannot find the proposal account';
  }

  const proposalData = program.readProposalAccountData(proposalAccountInfo);

  const groupAccount = new PublicKey(proposalData.config.group);
  console.log(`Group account: ${groupAccount.toBase58()}`);

  const protectedAccount = await program.protectedAccountKey(groupAccount);
  console.log(`Protected account: ${protectedAccount.toBase58()}`);

  const approve = await program.approve(proposalKey, proposalData.config, walletKey);

  const budget = ComputeBudgetProgram.requestUnits({ units: 1200000, additionalFee: 0 });
  return new Transaction().add(budget).add(approve);
}

export async function proposalClose(context: {
  connection: Connection,
  program: MultiSigProgram,
  proposalKey: PublicKey,
  groupKey: PublicKey,
  walletKey: PublicKey,
}): Promise<Transaction> {
  const { connection, program, proposalKey, groupKey, walletKey } = context;
  console.log(`Signing with account: ${walletKey.toBase58()}`);

  const proposalAccountInfo = await connection.getAccountInfo(proposalKey);

  if (proposalAccountInfo === null) {
    throw 'Error: cannot find the proposal account';
  }

  const proposalData = program.readProposalAccountData(proposalAccountInfo);
  const groupAccount = new PublicKey(proposalData.config.group);
  console.log('Group account:', groupAccount.toBase58());

  const protectedAccount = await program.protectedAccountKey(groupAccount);
  console.log('Protected account:', protectedAccount.toBase58());

  return new Transaction().add(program.closeProposal(proposalKey, walletKey, groupKey));
}
