import { useEffect, useState } from 'react';
import { NeonProgramStatus } from '../models';
import { useSettingsStorage } from './settings';
import { CommonApi } from '../api/CommonApi';

export default function useProxyInfo(api: CommonApi, delay = 2000): NeonProgramStatus | undefined {
  const [status, setStatus] = useState<NeonProgramStatus>();
  const [settings] = useSettingsStorage();
  const defDelay = delay;

  useEffect(() => {
    let timeout: any = null;

    const updateStatus = () => {
      api.getEvmParams().then(data => {
        setStatus(data);
        delay = defDelay;
        timeout = setTimeout(updateStatus, delay);
      }).catch(error => {
        setStatus(undefined);
        console.error(error?.message ?? `Can't get status update...`);
        delay = delay * 2;
        timeout = setTimeout(updateStatus, delay);
      });
    };

    updateStatus();
    return () => timeout && clearTimeout(timeout);
  }, [settings]);

  return status;
}
