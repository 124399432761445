import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { stringShort } from '../../../utils';
import { useSettingsStorage } from '../../../hooks';
import { environment } from '../../../environments/config/environment';
import CopyText from './CopyText';

const ExplorerCopy: FC<{ hash: string; type: 'address' | 'tx', className?: string, style?: any }> = (props) => {
  const { hash, type, className, style } = props;
  const [settings] = useSettingsStorage();
  const [max, setMax] = useState<number>();
  const ref = useRef<any>();

  const openLink = useCallback(() => {
    const params = { cluster: settings.network, customUrl: settings.solanaRpcApi };
    window.open(`${environment.url.explorer}/${type}/${hash}?${new URLSearchParams(params).toString()}`, '_blank');
  }, [settings, hash, type]);

  const getMaxSize = () => {
    const min_h = 20;
    const w = ref.current?.clientWidth;
    const h = ref.current?.clientHeight > min_h ? ref.current.clientHeight : min_h;
    const height = Math.round(h / 1.8);
    const maxSize = Math.floor(w / height);
    setMax(maxSize > 10 ? maxSize : 10);
  };

  useEffect(() => {
    window.addEventListener('resize', getMaxSize);
    return () => {
      window.removeEventListener('resize', getMaxSize);
    };
  }, []);
  useEffect(getMaxSize, []);

  return <div className={`flex items-center${className ? ` ${className}` : ``}`} ref={ref}
              style={style}>
    <CopyText text={hash} onClick={openLink}>{stringShort(hash, max)}</CopyText>
  </div>;
};

export default ExplorerCopy;
