import { useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import Account from '../components/Account';
import { useProgram } from '../../common/components/wallet/ProgramContext';
import { GroupsState } from '../../models';
import EvmStatus from '../components/EvmStatus';
import { AccountData } from '../models';

const Header = () => {
  const login = useState<boolean>(false);
  const [account, setAccount] = useState<AccountData>();
  const [groups, setGroups] = useState<GroupsState[]>([]);
  const { connected, publicKey: walletKey } = useWallet();
  const { accounts, program } = useProgram();

  useEffect(() => {
    if (walletKey && accounts?.length) {
      const wallet = walletKey.toBase58();
      const account = accounts.find(i => i.account === wallet);
      if (account) {
        account.access = groups.length > 0;
      }
      setAccount(account);
    }
  }, [walletKey, accounts, groups]);

  useEffect(() => {
    if (walletKey) {
      program.groups(walletKey).then(setGroups);
    }
  }, [walletKey]);

  return <header className={'flex flex-row justify-center transition z-10'}>
    <div className={'flex flex-row items-left justify-between w-full max-w-2xl p-4'}>
      <EvmStatus />
      {connected && <Account account={account} login={login} className={'ml-2'} />}
    </div>
  </header>;
};

export default Header;
