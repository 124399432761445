import React, { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RouteObject } from 'react-router/lib/router';
import { useWallet } from '@solana/wallet-adapter-react';
import { RoutePath } from '../../models';
import ProposalCreatedPage from '../pages/ProposalCreatedPage';
import EmergencyLayout from './EmergencyLayout';
import NewLayout from './NewLayout';

const ProposalsPage = lazy(() => import('../pages/ProposalsPage'));
const ProposalPage = lazy(() => import('../pages/ProposalPage'));
const ProposalSelectPage = lazy(() => import('../pages/ProposalSelectPage'));
const ProposalCreatePage = lazy(() => import('../pages/ProposalCreatePage'));
const ProposalCommentPage = lazy(() => import('../pages/ProposalCommentPage'));
const ProposalConfirmedPage = lazy(() => import('../pages/ProposalConfirmedPage'));
const EmergencySettingsPage = lazy(() => import('../pages/EmergencySettingsPage'));
const NotFoundPage = lazy(() => import('../../common/pages/404'));

function RequireAuth() {
  const { publicKey, connecting } = useWallet();
  const a = false;
  if (a && !connecting && !publicKey) {
    return <Navigate to={`/${RoutePath.proposals}`} replace />;
  }

  return <><Outlet /></>;
}

export const emergencyRouterPath: RouteObject = {
  path: RoutePath.home, children: [
    {
      path: RoutePath.home, element: <EmergencyLayout />, children: [
        { path: RoutePath.proposals, element: <ProposalsPage /> },
      ]
    },
    {
      path: RoutePath.home, element: <NewLayout />, children: [
        { path: RoutePath.select, element: <ProposalSelectPage /> },
        { path: RoutePath.approved, element: <ProposalConfirmedPage /> },
        { path: RoutePath.proposal, element: <ProposalPage /> },
        { path: RoutePath.network, element: <EmergencySettingsPage /> },
        {
          path: RoutePath.propose, element: <RequireAuth />, children: [
            { path: RoutePath.root, element: <ProposalCreatePage /> },
            { path: RoutePath.comment, element: <ProposalCommentPage /> },
            { path: RoutePath.created, element: <ProposalCreatedPage /> }
          ]
        },
        { path: RoutePath.all, element: <NotFoundPage /> }
      ]
    }
  ]
};
