import { PublicKey } from '@solana/web3.js';

export enum PropositionKind {
  Create,
  Transfer,
  Upgrade,
  UpgradeMultisig,
  DelegateUpgradeAuthority,
  DelegateMintAuthority,
  DelegateTokenAuthority,
  MintTo,
  CreateTokenAccount,
  TransferToken,
  UpgradeEVM
}

export type Proposition =
  | Create
  | Transfer
  | Upgrade
  | UpgradeEVM
  | UpgradeMultisig
  | DelegateUpgradeAuthority
  | DelegateMintAuthority
  | DelegateTokenAuthority
  | MintTo
  | CreateTokenAccount
  | TransferToken;

export type PropositionKeys = keyof Proposition;

export interface Create {
  kind: PropositionKind.Create,
  lamports: number;
  finalApproverKey: PublicKey;
}

export interface Transfer {
  kind: PropositionKind.Transfer;
  destination: PublicKey;
  amount: number;
}

export interface Upgrade {
  kind: PropositionKind.Upgrade;
  buffer: PublicKey;
  program: PublicKey;
}

export interface UpgradeEVM {
  kind: PropositionKind.UpgradeEVM;
  buffer: PublicKey;
  program: PublicKey;
}

export interface UpgradeMultisig {
  kind: PropositionKind.UpgradeMultisig;
  buffer: PublicKey;
}

export interface DelegateUpgradeAuthority {
  kind: PropositionKind.DelegateUpgradeAuthority;
  target: PublicKey;
  newAuthority: PublicKey;
}

export interface DelegateMintAuthority {
  kind: PropositionKind.DelegateMintAuthority;
  target: PublicKey;
  newAuthority: PublicKey;
}

export interface DelegateTokenAuthority {
  kind: PropositionKind.DelegateTokenAuthority;
  target: PublicKey;
  newAuthority: PublicKey;
}

export interface MintTo {
  kind: PropositionKind.MintTo;
  mint: PublicKey;
  destination: PublicKey;
  amount: number;
}

export interface CreateTokenAccount {
  kind: PropositionKind.CreateTokenAccount;
  mint: PublicKey;
  seed: string;
}

export interface TransferToken {
  kind: PropositionKind.TransferToken;
  source: PublicKey;
  destination: PublicKey;
  amount: number;
}
