import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { RoutePath } from '../../models';
import { ArrowDownIcon } from '../../common/icons';
import ExplorerCopy from '../../common/components/common/ExplorerCopy';
import { NeonProgram, ProxyStatus } from '../models';
import { useProgram } from '../../common/components/wallet/ProgramContext';
import { useOnClickOutside } from '../../hooks';
import { MdSettings } from 'react-icons/md';

const EvmStatus = () => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState<boolean>(false);
  const [status, setStatus] = useState<ProxyStatus>(ProxyStatus.unknown);
  const [version, setVersion] = useState<string>();
  const [neonProgram, setProgram] = useState<NeonProgram>();
  const { program, proxyInfo } = useProgram();

  const toggleShow = (): void => {
    setShow(!show);
    accountInfo();
  };

  const accountInfo = async (): Promise<void> => {
    const [authorityKey] = await program.getMaintenanceRecordAddress(program.neonEmvKey, program.maintenanceKey);
    await setProgram({
      programKey: program.neonEmvKey.toBase58(),
      authorityKey: authorityKey.toBase58()
    });
  };

  const updateFavicon = (status?: string): void => {
    let link = document.querySelector<HTMLLinkElement>(`link[rel~='icon']`);
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `/favicon${status ? `_${status}` : ''}.png`;
  };

  const statusBg = useMemo(() => {
    let result = { bg: '#FFF3CA', color: '#FCCA1B' };
    switch (status) {
      case ProxyStatus.work:
        result = { bg: '#DCFCE7', color: '#69F287' };
        break;
      case ProxyStatus.stop:
        result = { bg: '#FFE4E4', color: '#E12121' };
        break;
      case ProxyStatus.unknown:
        result = { bg: '#FFF3CA', color: '#FCCA1B' };
        break;
    }
    return result;
  }, [status]);

  useEffect(() => {
    if (proxyInfo) {
      setStatus(proxyInfo.NEON_STATUS_NAME as ProxyStatus);
      setVersion(proxyInfo.NEON_PKG_VERSION);
    } else {
      setStatus(ProxyStatus.unknown);
      setVersion('-');
    }
  }, [proxyInfo]);

  useEffect(() => {
    let result = 'unknown';
    switch (status) {
      case ProxyStatus.work:
        result = 'online';
        break;
      case ProxyStatus.stop:
        result = 'offline';
        break;
      case ProxyStatus.unknown:
        result = 'unknown';
        break;
    }
    updateFavicon(result);
  }, [status]);

  useOnClickOutside(bodyRef, () => {
    setShow(false);
  });

  return <>
    {show && <div
      className={'fixed bg-[#00000026] top-0 right-0 bottom-0 left-0 backdrop-blur-[5.5px] ease-in-out duration-200 opacity-1 z-10'} />}
    <div ref={bodyRef} className={`relative w-full z-10`}>
      <div className={'absolute w-full p-4 rounded-[10px] bg-[#F5F5F5] cursor-pointer'}
           onClick={toggleShow}>
        <div className={'flex flex-row items-center'}>
          <div className={'flex flex-row items-center w-full'}>
            <NavLink to={`/${RoutePath.home}`} onClick={e => e.stopPropagation()}
                     className={'w-[16px] h-[16px] rounded-full transition-colors duration-150'}
                     style={{ background: statusBg.color }} />
            <a className={'ml-2 text-base text-[#06010D]'}>
              EVM {version} is {status}
            </a>
          </div>
          <ArrowDownIcon className={`ease-in-out duration-200 ${show ? 'rotate-180' : ''}`} />
        </div>
        <div
          className={`flex flex-row justify-center w-full z-20 overflow-hidden ease-in-out duration-200`}
          style={{
            height: show ? 'auto' : '0',
            opacity: show ? 1 : 0,
            paddingTop: show ? '14px' : 0,
            visibility: show ? 'visible' : 'hidden'
          }}>
          <div className={'w-full'}>
            {neonProgram?.programKey &&
              <dl className={'flex flex-col text-[#7C7C7C] text-base mb-3'}>
                <dt className={'text-base bold text-black'}>Program</dt>
                <dd className={'flex flex-row'}>
                  <ExplorerCopy hash={neonProgram?.programKey} type='address' />
                </dd>
              </dl>}
            {neonProgram?.authorityKey &&
              <dl className={'flex flex-col text-[#7C7C7C] text-base'}>
                <dt className={'text-base bold text-black'}>Authority</dt>
                <dd className={'flex flex-row'}>
                  <ExplorerCopy hash={neonProgram?.authorityKey} type='address' />
                </dd>
              </dl>}
            <Link to={`/${RoutePath.network}`} className={'btn-sm icon absolute right-4 bottom-4 z-10'}>
              <MdSettings />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default EvmStatus;
