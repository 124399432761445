import { AccountData } from '../maintenance/models';
import {
  NeonProgramStatus,
  NetworkType,
  ProgramBuffer,
  RPCResponse,
  SettingsFormState,
} from '../models';

export class CommonApi {
  network: NetworkType | undefined;
  neonProxyRpcApi = '';
  solanaRpcApi = '';

  async engineers(): Promise<AccountData[]> {
    return await fetch('/data/engineers.json').then(res => res.json());
  }

  async buffer(): Promise<ProgramBuffer[]> {
    return await fetch('/data/buffer.json').then(res => res.json());
  }

  async getEvmParams(): Promise<NeonProgramStatus> {
    return this.proxy<NeonProgramStatus>('neon_getEvmParams', []).then(d => d.result);
  }

  async rpc<T>(url: string, method: string, params: unknown[] = []): Promise<RPCResponse<T>> {
    const id = Date.now();
    const body = { id, jsonrpc: '2.0', method, params };
    const request = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });
    return await request.json();
  }

  async proxy<T>(method: string, params: unknown[] = []): Promise<RPCResponse<T>> {
    return this.rpc<T>(this.neonProxyRpcApi, method, params);
  }

  async solana<T>(method: string, params: unknown[] = []): Promise<RPCResponse<T>> {
    return this.rpc<T>(this.solanaRpcApi, method, params);
  }

  constructor(settings: SettingsFormState) {
    this.network = settings.network;
    this.neonProxyRpcApi = settings.neonProxyRpcApi;
    this.solanaRpcApi = settings.solanaRpcApi;
  }
}
