import { useConnection } from '@solana/wallet-adapter-react';
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { MaintenanceProgram } from '../../../programs/maintenance/program';
import { environment } from '../../../environments/config/environment';
import { CommonApi } from '../../../api/CommonApi';
import { AccountData, ProgramContextState } from '../../../maintenance/models';
import { ConfirmDialogProvider } from '../common/ConfirmDialog';
import {
  useAccountStorage,
  useBufferStorage,
  useFormStorage,
  useRequestBuffers,
  useSettingsStorage
} from '../../../hooks';
import useProxyInfo from '../../../hooks/status';

export const ProgramContext = createContext<ProgramContextState>({} as ProgramContextState);

export function useProgram(): ProgramContextState {
  return useContext(ProgramContext);
}

export const ProgramProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { connection } = useConnection();
  const [settings] = useSettingsStorage();
  const api = new CommonApi(settings);
  const accountStorage = useAccountStorage();
  const bufferStorage = useBufferStorage();
  const formStorage = useFormStorage();
  const buffers = useRequestBuffers(api);
  const proxyInfo = useProxyInfo(api, 5000);
  const [accounts, setAccounts] = useState<AccountData[]>([]);

  const program = useMemo(() => {
    return new MaintenanceProgram({
      maintenance: settings.maintenanceProgramId!,
      neonEvm: settings.neonEvmProgramId!,
      multisig: settings.multisigProgramId!,
      memo: environment.memo,
      payer: environment.payer,
      connection
    });
  }, [connection]);

  useEffect(() => {
    api.engineers().then(setAccounts);
  }, []);

  return (
    <ProgramContext.Provider
      value={{
        program,
        api,
        accounts,
        accountStorage,
        buffers,
        bufferStorage,
        formStorage,
        proxyInfo
      }}>
      <ConfirmDialogProvider>
        {children}
      </ConfirmDialogProvider>
    </ProgramContext.Provider>
  );
};
