export const enum RoutePath {
  all = '*',
  root = '',
  home = '',
  comment = 'comment',
  about = 'about',
  groups = 'multisig',
  group = 'multisig/:groupId',
  groupCreate = 'multisig/create',
  proposalCreate = 'multisig/:groupId/propose',
  groupProposal = 'multisig/:groupId/:proposalId',
  settings = 'settings',

  proposals = '',
  proposal = 'p/:proposalId',
  approved = 'p/:proposalId/approved',
  select = 'select',
  propose = 'propose',
  created = 'created',
  network = 'network',
}

export const enum QueryParams {
  address = 'a',
  buffer = 'b',
  proposal = 'p',
  signature = 's',
  step = 'step',
  tx = 'tx'
}
